import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Rating from "react-rating";
import { toast } from "react-toastify";

import PretendardText from "../../atoms/text/pretendardText";
import MainBtn from "../../atoms/button/mainBtn";
import TitleInput from "../../molecules/input/titleInput";
import CameraFileForm from "../../atoms/fileForm/cameraFileForm";
import useDisplay from "../../../hooks/useDisplay";
import ReviewApi from "../../../api/reviewApi";
import { errorHandler } from "../../../util/errorHandler";
import CommonApi from "../../../api/commonApi";

function ReviewModal({
  visiable,
  setVisiable,
  isScroll,
  reviewId,
  merchId,
  reviewListFunc,
  textReviewReword,
  photoReviewReword,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [content, setContent] = useState("");
  const [contentErrorM, setContentErrorM] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [rating, setRating] = useState(0);
  const [deleteIds, setDeleteIds] = useState([]);
  const [originReviewId, setOriginReviewId] = useState();

  useEffect(() => {
    if (!isScroll) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 99%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isScroll]);

  useEffect(() => {
    if (reviewId) {
      reviewDetailFunc();
    }
  }, [reviewId]);

  const reviewDetailFunc = async () => {
    try {
      let response = (await ReviewApi.myReviewDetail(reviewId)).data.data
        .content;

      setOriginReviewId(response.id);
      setRating(response.score);
      setContent(response.content);
      setSelectedFiles(response.images);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const reviewAddFunc = async () => {
    try {
      let images = selectedFiles.map((item) => {
        return {
          imageOriginFileName: item.imageOriginFileName,
          imageServerFileName: item.imageServerFileName,
        };
      });

      const obj = {
        score: rating,
        content: content,
        images: images,
      };

      await ReviewApi.reviewAdd(merchId, obj);
      await reviewListFunc();
      setVisiable(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const reviewEditFunc = async () => {
    try {
      let selectedFilesTemp = selectedFiles.filter(
        (item) => !item.hasOwnProperty("id")
      );

      let images = selectedFilesTemp.map((item) => {
        return {
          imageOriginFileName: item.imageOriginFileName,
          imageServerFileName: item.imageServerFileName,
        };
      });

      const obj = {
        score: rating,
        content: content,
        images: images,
        deleteIds: deleteIds,
      };

      await ReviewApi.myReviewEdit(originReviewId, obj);
      await reviewListFunc();
      setVisiable(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const handleChange = async (e) => {
    if (selectedFiles.length >= 3) {
      toast("Tối đa 3 trang");

      return;
    }

    const filesArray = Array.from(e.target.files);

    if (filesArray.length > 3) {
      toast("Tối đa 3 trang");
      return;
    }
    filesArray.forEach(async (fileData) => {
      const file = fileData;
      const fileReader = new FileReader();

      const serverFileName = await fileUploadFunc(
        file,
        fileData.name.split(".").pop()
      );

      if (serverFileName) {
        fileReader.readAsDataURL(file);
        fileReader.onload = (data) => {
          const fileObject = {
            imageOriginFileName: file.name,
            imageServerFileName: serverFileName,
            fileUrl: data.target.result,
          };

          setSelectedFiles((prevFiles) => [...prevFiles, fileObject]);
        };
      }
    });

    // input 값을 초기화하여 동일한 파일을 다시 선택할 수 있게 함
    e.target.value = "";
  };

  const fileUploadFunc = async (file, fileType) => {
    try {
      const obj = {
        path: "review/",
        extension: fileType,
      };

      const response = await CommonApi.fileUpload(obj);

      await CommonApi.cloudFileUpload(response.data.data.presignedUrl, file);

      return response.data.data.serverFileName;
    } catch (error) {
      toast("Tải hình ảnh đã thất bại.");
      return;
    }
  };

  const deleteFile = (fileName, id) => {
    let temp = selectedFiles.filter(
      (item) => item.imageServerFileName != fileName
    );
    setSelectedFiles(temp);

    if (id) {
      setDeleteIds((prevID) => [...prevID, id]);
    }
  };

  return (
    <ReviewModalWrapper $isMobile={isMobile}>
      <ReviewModalContainer $isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              position: "relative",
              marginBottom: isMobile ? 20 : 30,
            }}
          >
            <PretendardText
              style={{
                color: "#313131",
                fontSize: isMobile ? 20 : "24px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Viết đánh giá
            </PretendardText>
            <img
              onClick={() => {
                setVisiable(false);
              }}
              style={{
                width: isMobile ? 24 : 30,
                height: isMobile ? 24 : 30,
                cursor: "pointer",
                position: "absolute",
                right: 0,
              }}
              src={"/assets/icons/ic-closed-30.png"}
              alt="ic-closed-30"
            />
          </div>

          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <PretendardText
                style={{
                  color: "#313131",
                  fontSize: isMobile ? 14 : "16px",
                  fontWeight: 700,
                  textAlign: "center",
                  marginBottom: 4,
                }}
              >
                Sự hài lòng về sản phẩm
              </PretendardText>
              <PretendardText
                style={{
                  color: "#939393",
                  fontSize: "12px",
                  fontWeight: 400,
                  textAlign: "center",
                  marginBottom: 16,
                }}
              >
                Nhập tên sản phẩm.
              </PretendardText>
              <Rating
                style={{
                  height: isMobile ? 35.5 : 46,
                }}
                value={rating}
                initialRating={rating}
                onChange={setRating}
                emptySymbol={
                  <img
                    src="/assets/icons/rating_d.png"
                    className="icon"
                    style={{
                      width: isMobile ? 35.5 : 46,
                      height: isMobile ? 35.5 : 46,
                    }}
                  />
                }
                fullSymbol={
                  <img
                    src="/assets/icons/rating_a.png"
                    className="icon"
                    style={{
                      width: isMobile ? 35.5 : 46,
                      height: isMobile ? 35.5 : 46,
                    }}
                  />
                }
              />
            </div>

            <div
              style={{
                position: "relative",
              }}
            >
              <TitleInput
                isTextarea={true}
                title={"Đánh giá chi tiết"}
                essential={false}
                placeholder={"Vui lòng nhập nội dung."}
                type={"textare"}
                onChange={(e) => {
                  setContent(e.target.value);
                }}
                value={content}
                warningMessage={contentErrorM}
                style={{
                  marginBottom: 20,
                }}
                inputStyle={{
                  height: isMobile ? 145 : 185,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 16,
                  right: 16,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    color: "#C4C4C4",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  {content.length}/1,000
                </PretendardText>
              </div>
            </div>

            {selectedFiles.length > 0 ? (
              <MenuOverflowXHidden>
                {selectedFiles.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <img
                        onClick={() => {
                          deleteFile(item.imageServerFileName, item.id);
                        }}
                        style={{
                          width: 72,
                          height: 72,
                          cursor: "pointer",
                          borderRadius: 8,
                        }}
                        src={
                          item.fileUrl
                            ? item.fileUrl
                            : `${process.env.REACT_APP_MEDIA_URL}${item.imageServerFileName}`
                        }
                        alt="file"
                      />

                      <img
                        onClick={() => {
                          deleteFile(item.imageServerFileName, item.id);
                        }}
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          position: "absolute",
                          top: 3,
                          right: 3,
                        }}
                        src="/assets/icons/subBTn_7_deleteBTN.png"
                        alt="subBTn_7_deleteBTN"
                      />
                    </div>
                  );
                })}
              </MenuOverflowXHidden>
            ) : null}

            <CameraFileForm multiple={"multiple"} handleChange={handleChange} />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: isMobile ? 20 : 40,
              width: "100%",
            }}
          >
            <MainBtn
              disabled={content ? false : true}
              onClick={() => {
                if (merchId) {
                  reviewAddFunc();
                } else {
                  reviewEditFunc();
                }
              }}
            >
              <PretendardText
                style={{
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                Đăng ký
              </PretendardText>
            </MainBtn>
            <PretendardText
              style={{
                color: "#313131",
                fontSize: "14px",
                fontWeight: 500,
                marginTop: isMobile ? 8 : 40,
              }}
            >
              Hướng dẫn viết đánh giá tích điểm
            </PretendardText>
            <PretendardText
              style={{
                color: "#939393",
                fontSize: "14px",
                fontWeight: 400,
                marginTop: 8,
              }}
            >
              {`Nhận ${Number(
                textReviewReword
              ).toLocaleString()}P khi đánh giá bằng văn bản\nNhận ${Number(
                photoReviewReword
              ).toLocaleString()}P khi đánh giá văn bản kèm hình ảnh / video`}
            </PretendardText>
          </div>
        </div>
      </ReviewModalContainer>
    </ReviewModalWrapper>
  );
}

export default ReviewModal;

const ReviewModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.$isMobile ? "0px 20px" : "70px 0px")};
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 1000;
`;

const ReviewModalContainer = styled.div`
  width: ${(props) => (props.$isMobile ? "100%" : "520px")};
  padding: ${(props) => (props.$isMobile ? "40px 20px" : "40px")};
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 8px 8px 35px 0px rgba(0, 0, 0, 0.45);
`;

const MenuOverflowXHidden = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;

  width: 100%;

  gap: 12px;

  margin-bottom: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;
